<template>
  <div class="flex align-center flex-col justify-center my-4 w-full">
    <div class="container my-6 flex justify-end">
      <div v-if="message" class="bg-gray-300 px-4 py-2 mr-4 rounded">
        {{ message._message }}
      </div>

      <AppButton
        :loading="loading"
        @clicked="save()"
        class="mr-2"
        color="bg-green-500 hover:bg-green-700 text-white"
      >
        Salvar verbete
      </AppButton>
    </div>

    <div class="flex container items-start">
      <div
        class="mr-6 w-3/4"
        v-if="languages.length > 0 && langs && Object.keys(langs).length > 0"
      >
        <nav class="flex mb-6">
          <div
            v-for="language in languages"
            :key="`${language.abbreviation}-nav`"
            @click.prevent="active = language.abbreviation"
            class="px-4 py-2 rounded mr-4 text-gray-600 border border-gray-300 cursor-pointer"
            :class="{
              'bg-gray-100 border-gray-700 text-gray-700':
                active == language.abbreviation,
              'bg-gray-300': active != language.abbreviation
            }"
          >
            {{ language.title }}
          </div>
        </nav>

        <div
          v-for="language in languages"
          :key="language.abbreviation"
          :class="{ hidden: active != language.abbreviation }"
        >
          <label class="block mb-6 bg-white rounded shadow p-4 ">
            <span class="label"
              >Título na lista de verbetes [{{ language.title }}]</span
            >
            <input
              type="text"
              class="input"
              v-model="langs[language.abbreviation].title"
            />
          </label>
          <div class="bg-white rounded shadow p-4 mb-6">
            <TextEditor v-model="langs[language.abbreviation].content" />
          </div>
        </div>
      </div>

      <div v-else class="mr-6 w-3/4">
        <nav class="flex mb-6">
          <div
            class="bg-gray-300 px-4 py-2 rounded mr-4 text-gray-600 border border-gray-300 cursor-pointer"
          >
            Carregando...
          </div>
        </nav>
        <div class="bg-white rounded shadow p-4 mb-6"></div>
      </div>

      <div class="w-1/4 min-w-1/4 bg-white rounded shadow p-4">
        <label class="block mb-6">
          <span class="label">Título gerenciador</span>
          <input type="text" class="input" v-model="entry.titleAdmin" />
          <p class="italic text-gray-500 text-sm ml-1 mt-1">
            Só aparece pra você.
          </p>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TextEditor from "@/components/TextEditor/TextEditor";
import AppButton from "@/components/AppButton";

export default {
  name: "GlossaryForm",
  components: {
    TextEditor,
    AppButton
  },
  filters: {},
  props: {},
  data: function() {
    return {
      loading: false,
      active: null,
      message: null,

      entry: {
        titleAdmin: "",
        langs: {}
      },
      langs: null
    };
  },
  computed: {
    ...mapState({
      entries: state => state.Glossary.list,
      languages: state => state.Settings.languages
    })
  },
  watch: {},
  async created() {},
  async mounted() {
    await this.$store.dispatch("Settings/list");

    if (this.entries.length == 0) {
      await this.$store.dispatch("Glossary/list");
    }

    const param = this.$route.params.id;

    if (param == "adicionar") {
      if (this.languages.length > 0) {
        const langs = {};
        this.languages.forEach(item => {
          langs[item.abbreviation] = {
            title: "",
            content: ""
          };
        });

        this.active = this.languages[0].abbreviation;

        this.langs = langs;
      }
    } else {
      const entry = await this.$store.dispatch("Glossary/single", param);
      const langs = await this.$store.dispatch("Glossary/langs", entry._id);

      this.active = this.languages[0].abbreviation;

      if (entry) {
        this.languages.forEach(item => {
          if (!langs[item.abbreviation]) {
            langs[item.abbreviation] = {
              title: "",
              content: ""
            };
          }
        });

        this.entry = entry;
        this.langs = langs;
      }
    }
  },
  methods: {
    async save() {
      this.loading = true;
      this.message = null;

      const param = this.$route.params.id;

      if (param == "adicionar") {
        const entry = {
          titleAdmin: this.entry.titleAdmin,
          langs: {},
          date: Date.now()
        };

        const langsArray = Object.keys(this.langs);
        for (const lang of langsArray) {
          entry.langs[lang] = this.langs[lang].title;
        }

        const langs = {
          ...this.langs
        };

        const response = await this.$store.dispatch("Glossary/add", [
          entry,
          langs
        ]);
        if (response) {
          this.message = response;
        }
      } else {
        const entry = {
          ...this.entry
        };

        const langsArray = Object.keys(this.langs);
        for (const lang of langsArray) {
          entry.langs[lang] = this.langs[lang].title;
        }

        const langs = {
          ...this.langs
        };

        const response = await this.$store.dispatch("Glossary/update", [
          entry,
          langs
        ]);
        if (response) {
          this.message = response;
        }
      }

      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss"></style>
